import { useEffect, useState } from "react"
import useProductListi18n from "@/i18n/useProductListi18n"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { useDispatch, useSelector } from "react-redux"
import sortStyle from "@/components/ProductList/v2/Sort/index.module.scss"
import filterStyle from "@/components/ProductList/v2/Filter/index.module.scss"
import ProductListView from "@/components/ProductList/v2/ProductListView"
import { getConfig } from "@/constants/config"
import {
  DEFAULT_PRODUCT_COUNT_PLP,
  PRODUCTS_PER_PAGE,
  resourceFacetLabels,
} from "@/constants/index"
import { selectAuthState } from "@/store/features/authSlice"
import PLPLoader from "@/components/ProductList/v2/PLPLoader"
import styles from "@/components/ProductList/v2/ProductListView/index.module.scss"
import productTileStyles from "@/components/ProductList/v2/ProductTile/index.module.scss"
import {
  getSelectedSort,
  getSortFromUrl,
  getFavouraties,
  getUserPersona,
  getPriceSortQuery,
  replaceSearchUrl,
  getAllFilterQueries,
  getBadgeValue,
} from "@/utils/helper"
import { getProducts } from "@/store/features/productListSlice"
import { selectProductListState } from "@/store/features/productListSlice"
import usePrevious from "@/hooks/usePrevious"
import { addAnalyticsShowEvent } from "@/components/ProductList/v2/analytics"
import { getRowsInitialValue } from "@/utils/product"
import { compareState } from "@/store/features/compareSlice"
import useIsSsr from "@/hooks/useIsSsr"
import { scrollToProduct } from "@/components/Search/v1/ProductsTab/helper"
import { useRouter } from "next/router"

const ProductList = props => {
  const isServer = typeof window === "undefined"
  const isSsr = useIsSsr()
  const router = useRouter()
  let plp
  let compareData
  const { eventHandler } = props
  const productsPerPage = PRODUCTS_PER_PAGE
  const DEFAULT_PAGINATION_VALUE = DEFAULT_PRODUCT_COUNT_PLP
  const { data: componentProps } = props
  const { access_token: accessToken, user } = useSelector(selectAuthState)
  const {
    categoryKey,
    attributevalue,
    attributeName,
    isServiceParts = "",
  } = componentProps
  if (isServer) {
    plp = eventHandler?.store?.plp
    compareData = {}
  } else {
    // eslint-disable-next-line
    plp = useSelector(selectProductListState).plp
    // eslint-disable-next-line
    compareData = useSelector(compareState)
  }

  const dispatch = useDispatch()
  const {
    data,
    total = 0,
    facets,
    facetLabels,
    status,
    initialLoad,
    colors,
    grouped,
    fusion = {},
  } = plp
  const rowsInitialValue = getRowsInitialValue()
  const [paginationState, setPaginationState] = useState({
    rows: rowsInitialValue,
    start: 0,
  })
  const { rows, start } = paginationState
  const [promoData, setPromoData] = useState({ promos: [], isUpdate: false })
  const prevRows = usePrevious(rows)
  const [isLoadMore, setIsLoadMore] = useState(false)

  const [general, setGeneral] = useState({ brandName: "", badgeText: "" })
  const [sessionId, setSessionId] = useState("") // setting initial state of sessionId
  // Need to read from env file
  const [info, setInfo] = useState({
    currencyCode: "USD",
    currencySign: "$",
    locale: "en-US",
  })
  const [state, setState] = useState({
    position: "",
    promoJson: {},
  })

  const [fusionQueryId, setFusionQueryId] = useState("")
  const [curFilterQueries, setCurFilterQueries] = useState(null)
  const prevCurFilterQueries = usePrevious(curFilterQueries)

  const [persona, setPersona] = useState("GST")
  const [isFusionLoaded, setIsFusionLoaded] = useState(false)
  const [dataPopulated, setDataPopulated] = useState(false)
  const [filterDataPopulated, setFilterDataPopulated] = useState(false)
  const [plpBadge, setPlpBadge] = useState({})
  useEffect(() => {
    if (isSsr) return
    getConfig().then(config => {
      const { plpConfigurations: { myArrayList: plpBadgeList = [] } = {} } =
        config
      const locale = _get(config, "internationalization.locale", "")
      const currencyCode = _get(config, "internationalization.currencyCode", "")
      const currencySign = _get(config, "internationalization.currencySign", "")
      const brandName = _get(config, "general.siteName")
      const displayOrderBadges =
        plpBadgeList.find(item => item.map.Key === "displayOrderBadges") ?? {}
      const {
        map: { Values: { myArrayList: displayOrderBadgeList = [] } = {} } = {},
      } = displayOrderBadges

      const plpBadges = {}
      displayOrderBadgeList.forEach(item => {
        plpBadges[item] = getBadgeValue(item, plpBadgeList)
      })

      const badgeText = {
        new: _get(config, "general.newBadgeName", ""),
        onSale: _get(config, "general.saleBadgeName", ""),
        exclusive: _get(config, "general.exclusiveBadgeName", ""),
      }
      const marketParamValues = _get(config, "marketParamValues", {})

      const { general: { serviceParts = "" } = {} } = config
      const general = {
        brandName: brandName,
        badgeText: badgeText,
        serviceParts,
      }
      const info = {
        locale: locale,
        currencyCode: currencyCode,
        currencySign: currencySign,
        marketParamValues,
      }
      const filterQuery = getAllFilterQueries(
        componentProps,
        fusion,
        marketParamValues
      )
      setCurFilterQueries(filterQuery)
      setGeneral(general)
      setInfo(info)
      setPlpBadge({
        plpBadges,
        displayOrderBadgeList,
      })
      const cookie = document?.cookie
      const cookieArray = cookie?.split(";")
      const sessionCookie = cookieArray.filter(item =>
        item?.includes("session#")
      )[0]
      const sessionIdString = sessionCookie?.split("|")[1]
      setSessionId(sessionIdString?.split("#")[1]) // setting the value of session id from cookie information
    })
  }, [isSsr, router])

  const staticTexts = useProductListi18n({
    componentProps,
    total: total,
    generalRef: general,
  })

  const [selectedSort, setSelectedSort] = useState({
    name: staticTexts.featured,
    query: ``,
  })
  const prevSort = usePrevious(selectedSort)

  const sorts = [
    { name: staticTexts.featured, query: `` },

    { name: staticTexts.priceDesc, query: `price+desc` },
    { name: staticTexts.priceAsc, query: `price+asc` },
    {
      name: staticTexts.atoz,
      query: `${grouped ? "CollapseSort_s+asc" : "NonCollapseSort_s+asc"}`,
    },
    {
      name: staticTexts.ztoa,
      query: `${grouped ? "CollapseSort_s+desc" : "NonCollapseSort_s+desc"}`,
    },
  ]
  const handleSort = selectedSort => {
    replaceSearchUrl("sort", selectedSort.query)
    setPaginationState(state => ({ ...state, start: 0 }))
    setSelectedSort(selectedSort)
  }
  const handleLoadMore = () => {
    setRowsSession(start + rows + rows)
    setPaginationState(state => ({ ...state, start: start + rows }))
    setIsLoadMore(true)
  }
  const setRowsSession = rows => {
    if (sessionStorage.rows) {
      const rowStorage = JSON.parse(sessionStorage.rows)
      rowStorage[window.location.pathname] = rows
      sessionStorage.rows = JSON.stringify(rowStorage)
    } else {
      sessionStorage.rows = JSON.stringify({ [window.location.pathname]: rows })
    }
  }

  const handleRows = rows => {
    setPaginationState({ rows, start: 0 })
    setRowsSession(rows)
  }

  useEffect(() => {
    if (accessToken && curFilterQueries !== null) {
      const personaTemp =
        user?.persona?.length > 0 ? user?.persona[0]?.split(":")[1] : "GST"
      setPersona(personaTemp)
      window.authAccessToken = accessToken
      // will trigger from handleLoadMore
      if (start) {
        generateListingUrl(isLoadMore, false)
      }
      // When downgrade pagination
      else if (start == 0 && rows && !!prevRows && prevRows > rows) {
        generateListingUrl(false, true)
      }
      // will trigger from handleRows
      else if (start == 0 && rows && !!prevRows && prevRows != rows) {
        generateListingUrl(false, false)
      }
      // sort and filter applied in url
      else if (curFilterQueries.length > 0 || selectedSort?.query) {
        generateListingUrl()
      }
      // On removing sort, needs to trigger for default sort
      else if (curFilterQueries.length === 0 && prevCurFilterQueries != null) {
        generateListingUrl()
      }
      // when there is a change in sort
      else if (prevSort && prevSort?.query != selectedSort?.query) {
        generateListingUrl()
      }
      // will trigger when there is a selected row value in session storage
      else if (
        (prevRows == rows && rows > DEFAULT_PAGINATION_VALUE) ||
        personaTemp != "GST"
      ) {
        generateListingUrl()
      }
    }
  }, [start, rows, accessToken, curFilterQueries, selectedSort, isLoadMore])
  useEffect(() => {
    if (!(attributevalue && attributeName)) {
      if (!data || (data && data.length === 3 && total > 3)) {
        generateListingUrl()
      }
    }
  }, [data, total, attributevalue, attributeName])
  useEffect(() => {
    if (!_isEmpty(fusion) && accessToken && !isFusionLoaded) {
      setIsFusionLoaded(true)
      const fusionQueryId = fusion?.fusionQueryId
      setFusionQueryId(fusionQueryId)
      const sortUrl = getSortFromUrl()
      const sortQuery = getSelectedSort(staticTexts, sortUrl)
      setSelectedSort(sortQuery)
      if (componentProps) {
        if (componentProps?.xfLink) {
          setState({ ...state, xfHtml: componentProps.xfLink })
        } else {
          // log.warn("Promo banner xF is not configured")
        }
      } else {
        // log.warn("Product List: Get URL Property not found")
      }
    }
  }, [fusion, accessToken])

  // Function to generate the plp request url.
  const generateListingUrl = async (update, enableScroll = true) => {
    const queries = curFilterQueries
    if (!update) {
      setPaginationState({ ...paginationState, start: 0 })
      setPromoData({ promos: [], isUpdate: false })
    }
    const fq = []
    const persona = getUserPersona()

    queries?.forEach(q => {
      const tag = `{!tag="${q.facet}"}`
      if (!q.facet.match(/^\*{2}/)) {
        q.facet === "Price_Range" || q.facet.indexOf("priceList") === 0
          ? fq.push(
              encodeURIComponent(
                `${
                  `{!tag="priceList.${persona}.finalPrice_d"}` +
                  `priceList.${persona}.finalPrice_d`
                }:(${q.value.join(" OR ")})`
              )
            )
          : fq.push(
              encodeURIComponent(
                `${tag + q.facet}:("${q.value.join('" OR "')}")`
              )
            )
        return
      }
      q.facet === "Price_Range" || q.facet.indexOf("priceList") === 0
        ? fq.push(
            `${`priceList.${persona}.finalPrice_d`.replace(
              /^\*{2}/,
              ""
            )}:(${encodeURIComponent(q.value)})`
          )
        : fq.push(
            `${q.facet.replace(/^\*{2}/, "")}:("${encodeURIComponent(
              q.value.join('","')
            )}")`
          )
    })
    const query = {
      fq,
      rows,
      start,
      fl: resourceFacetLabels.join(","),
    }
    if (selectedSort.query) {
      if (selectedSort.query === "price+asc") {
        query.sort = `${getPriceSortQuery(plp?.grouped)}+asc`
      } else if (selectedSort.query === "price+desc") {
        query.sort = `${getPriceSortQuery(plp?.grouped)}+desc`
      } else {
        query.sort = selectedSort.query
      }
    }
    if (enableScroll) setDataPopulated(false)

    const globalConfig = await getConfig()
    const { general: { serviceParts = "" } = {} } = globalConfig
    const collections = isServiceParts ? serviceParts : ""

    dispatch(
      getProducts({ profileName: categoryKey, query, update, collections })
    )
      .then(() => {
        setFilterDataPopulated(true)
        if (enableScroll) setDataPopulated(true)
      })
      .catch(() => {
        setFilterDataPopulated(true)
        if (enableScroll) setDataPopulated(false)
      })
  }

  const setPromoInfo = promo => {
    const promoDataNew = [...promoData.promos]
    if (
      promo &&
      !promoData.promos.find(item => item.position === promo.position)
    ) {
      // don't push duplicates
      promoDataNew.push(promo)
    }
    setPromoData({ promos: promoDataNew, isUpdate: true })
  }

  useEffect(() => {
    // checking if all responses are recorded
    if (isSsr) return
    const { isUpdate, promos } = promoData
    if (isUpdate) {
      const { position } = state
      const requestedPromos = []
      data.map((item, i) => {
        if ((i + 1) % position === 0) {
          requestedPromos.push(i)
        }
      })
      if (requestedPromos.length === promos.length) {
        addAnalyticsShowEvent(promos, setPromoData, data)
      }
    }
  }, [promoData.promos, promoData.isUpdate, isSsr])

  useEffect(() => {
    if (isSsr) return
    // plpLoadedEvent implementation
    const { position, xfHtml } = state
    const { promos } = promoData
    if (
      !data ||
      !data.length ||
      parseInt(data.length / position) === 0 ||
      !xfHtml
    ) {
      addAnalyticsShowEvent(promos, setPromoData, data)
    } else {
      // checking if all responses are recorded
      const requestedPromos = []
      data.map((item, i) => {
        if ((i + 1) % position === 0) {
          requestedPromos.push(i)
        }
      })
      if (requestedPromos.length === 0) {
        addAnalyticsShowEvent(promos, setPromoData, data)
      } else {
        setTimeout(() => {
          addAnalyticsShowEvent(promos, setPromoData, data)
        }, 5000)
      }
    }
  }, [data, state.position, componentProps.xfLink, isSsr])

  useEffect(() => {
    // To scrollup the product list on back
    if (dataPopulated) scrollToProduct()
  }, [dataPopulated])

  const sendPositionToParent = position => {
    setState({
      ...state,
      position: position,
    })
  }

  const handleFilter = appliedFilter => {
    setRowsSession(30)
    setIsLoadMore(false)
    setCurFilterQueries(appliedFilter)
  }

  return (
    <>
      {!_isEmpty(info) &&
      (!(attributeName && attributevalue) ||
        (attributevalue && attributeName && filterDataPopulated)) ? (
        <div data-testid={"product-list"}>
          <ProductListView
            staticTexts={staticTexts}
            sorts={sorts}
            selectedSort={selectedSort}
            info={info}
            onLoadMore={handleLoadMore}
            curRows={rows}
            onRows={handleRows}
            onSort={handleSort}
            onFilter={handleFilter}
            totalResults={total}
            displayShare={componentProps.enableSharePlp}
            displayPrint={componentProps.enablePrintPlp}
            productsPerPage={productsPerPage}
            data={data}
            colors={colors}
            loading={initialLoad ? false : status !== "succeeded"}
            {...componentProps}
            facets={facets ?? false}
            facetLabels={facetLabels}
            xfHtml={state.xfHtml}
            currFilters={curFilterQueries}
            // resetTitle={""} The value is not using any where, so not implemented now
            // urlTitle={""} The value is not using any where, so not implemented now
            isGrouped={grouped}
            favorites={getFavouraties()?.lineItems}
            setPromoData={setPromoInfo}
            sendPositionToParent={sendPositionToParent}
            promoData={promoData.promos}
            promoJson={
              componentProps?.xfjson ? JSON.parse(componentProps?.xfjson) : {}
            }
            fusionQueryId={fusionQueryId}
            parent="PLP"
            compareData={compareData}
            promoLayout={componentProps?.promoLayout}
            promoPlacementDesktop={componentProps?.promoPlacementDesktop}
            promoPlacementMobile={componentProps?.promoPlacementMobile}
            persona={persona}
            sessionId={sessionId}
            plpBadge={plpBadge}
          />
        </div>
      ) : (
        <div
          className={`${styles.productListWrapper} ${productTileStyles.productTileWrapper} ${sortStyle.productListingSearchWrapper} ${filterStyle.productListingFilterWrapper}`}
        >
          <PLPLoader />
        </div>
      )}
    </>
  )
}

export default ProductList
